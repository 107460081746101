


















































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import DateTimePickerV2, {
  IDateRangeValue,
} from '@/app/ui/components/DateTimePickerV2/index.vue'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import EmptyState from '@/app/ui/components/EmptyState/EmptyState.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import {
  ICancelListParameters,
  IOptions,
  ITableCell,
  IHeaderCell,
  IValidationStatusMapper,
  ICancelListParams,
} from '@/data/infrastructures/misc/interfaces/cancelValidation'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import controller from '@/app/ui/controllers/CancelValidationController'
import AscendingIcon from '@/app/ui/assets/ascending_icon.vue'
import {
  SortFields,
  SortType,
  pickupStatus,
} from '@/app/infrastructures/misc/Constants/cancelValidation'
import { Utils } from '@/app/infrastructures/misc'
import Badge from '@/app/ui/components/Badge/index.vue'
import { CourierShipmentCancels } from '@/domain/entities/CancelValidation'
import { Pagination } from '@/domain/entities/Pagination'
import Button from '@/app/ui/components/Button/index.vue'

@Component({
  components: {
    TextInput,
    DropdownSelect,
    DateTimePickerV2,
    DataTableV2,
    EmptyState,
    PaginationNav,
    LoadingOverlay,
    AscendingIcon,
    Badge,
    Button,
  },
})
export default class ListShipmentCancel extends Vue {
  controller = controller

  enumSelectedSort = SortType
  enumSortFields = SortFields
  enumPickupStatus = pickupStatus

  selectedSort: SortType | null = null

  statusOptions: IOptions[] = [
    {
      label: 'Semua',
      value: '',
    },
    {
      label: 'Valid',
      value: '1',
    },
    {
      label: 'Fiktif',
      value: '2',
    },
    {
      label: 'Need Validation',
      value: '0',
    },
  ]

  parameters: ICancelListParameters = {
    page: 1,
    perPage: 50,
    search: '',
    status: this.statusOptions[0],
    dateRange: {
      start: new Date(),
      end: new Date(),
    },
  }

  datepickerPresets = [
    {
      key: 'last31days',
      label: '31 Hari Terakhir',
      value: 31,
    },
  ]

  headers = [
    this.headerCellMapper('No.', '60px'),
    this.headerCellMapper('Shipment ID', '160px'),
    this.headerCellMapper('History ID', '140px'),
    this.headerCellMapper('Waktu Cancel', '188px'),
    this.headerCellMapper('Alasan Cancel', '188px'),
    this.headerCellMapper('Status', '130px', 'center'),
    this.headerCellMapper('Aksi', '196px', 'center'),
  ]

  courierData = {
    courierName: '-',
    courierId: '-',
    courierPhoneNumber: '-',
    courierType: '-',
    courier3LC: '-',
  }
  paginationData = new Pagination()
  tableData: ITableCell[][] = []

  created(): void {
    this.fetchShipmentCancelData(true)
  }

  get params(): ICancelListParams {
    return {
      page: this.parameters.page,
      perPage: this.parameters.perPage,
      keyword: this.parameters.search,
      status: this.parameters.status.value,
      endDate: Utils.formatDateWithIDLocale(
        <string>this.parameters.dateRange.end?.toDateString(),
        'YYYY-MM-DD'
      ),
      startDate: Utils.formatDateWithIDLocale(
        <string>this.parameters.dateRange.start?.toDateString(),
        'YYYY-MM-DD'
      ),
      column: this.selectedSort ? this.selectedSort?.split('-')[0] : '',
      sortDirection: this.selectedSort ? this.selectedSort?.split('-')[1] : '',
    }
  }

  get isFiltering(): boolean {
    return (
      this.parameters.search !== '' ||
      this.parameters.status.value !== '' ||
      Utils.setFormatDateTime(
        <Date>this.parameters.dateRange.start,
        0,
        0,
        0
      ) !== Utils.setFormatDateTime(new Date(), 0, 0, 0) ||
      Utils.setFormatDateTime(<Date>this.parameters.dateRange.end, 0, 0, 0) !==
        Utils.setFormatDateTime(new Date(), 0, 0, 0)
    )
  }

  public fetchShipmentCancelData(reset?: boolean): void {
    if (reset) {
      this.parameters.page = 1
    }

    const courierId = <string>this.$route.params.courierId

    controller.getCourierShipmentCancelList({
      courierId: courierId,
      params: this.params,
    })
  }

  public onSearch = Utils.debounce((val: string) => {
    this.parameters.search = val
    this.fetchShipmentCancelData(true)
  }, 500)

  public onChangeStatus(value: IOptions): void {
    if (!value) {
      this.parameters.status = this.statusOptions[0]
    }
    this.fetchShipmentCancelData(true)
  }

  public onChangeDateRange(value: IDateRangeValue): void {
    if (!value.start && !value.end) {
      this.parameters.dateRange = {
        start: new Date(Utils.setFormatDateTime(new Date(), 0, 0, 0)),
        end: new Date(Utils.setFormatDateTime(new Date(), 0, 0, 0)),
      }
    }
    this.fetchShipmentCancelData(true)
  }

  public onTableSort(sort: SortFields): void {
    switch (sort) {
      case SortFields.CREATED_AT:
        if (this.selectedSort === SortType.CREATED_AT_ASC) {
          this.selectedSort = SortType.CREATED_AT_DESC
        } else if (this.selectedSort === SortType.CREATED_AT_DESC) {
          this.selectedSort = null
        } else {
          this.selectedSort = SortType.CREATED_AT_ASC
        }
        break
    }
    this.fetchShipmentCancelData(true)
  }

  public onExport(): void {
    const courierId = <string>this.$route.params.courierId

    const payload: {
      courierId: number
      params: Record<string, string | number>
    } = {
      courierId: parseInt(courierId),
      params: {
        keyword: this.parameters.search,
        status: <string>this.parameters.status.value,
        endDate: Utils.formatDateWithIDLocale(
          <string>this.parameters.dateRange.end?.toDateString(),
          'YYYY-MM-DD'
        ),
        startDate: Utils.formatDateWithIDLocale(
          <string>this.parameters.dateRange.start?.toDateString(),
          'YYYY-MM-DD'
        ),
        column: this.selectedSort ? this.selectedSort?.split('-')[0] : '',
        sortDirection: this.selectedSort
          ? this.selectedSort?.split('-')[1].toUpperCase()
          : '',
        filename: `CRRCNC-${courierId}-${Utils.formatDateWithIDLocale(
          new Date().toISOString(),
          'YYYYMMDDHHmmss'
        )}`,
      },
    }

    this.controller.exportCourierShipmentCancelList(payload)
  }

  public validationStatusMapper(status: string): IValidationStatusMapper {
    switch (status) {
      case this.enumPickupStatus.VALID:
        return {
          text: 'Valid',
          color: 'success',
        }
      case this.enumPickupStatus.NEED_VALIDATION:
        return {
          text: 'Need<br>Validation',
          color: 'warning',
        }
      default:
        return {
          text: 'Fiktif',
          color: 'error-1',
        }
    }
  }

  private headerCellMapper(
    title: string | number,
    colWidth: string,
    textAlign?: string
  ): IHeaderCell {
    return {
      title: title,
      customStyle: {
        minWidth: colWidth,
        maxWidth: colWidth,
        textAlign: textAlign,
      },
    }
  }

  private tableCellMapper(
    value: string | number | Date | string[] | { [key: string]: string },
    colWidth: string,
    justifyContent?: string
  ): ITableCell {
    return {
      value,
      customStyle: {
        maxWidth: colWidth,
        minWidth: colWidth,
        display: 'flex',
        justifyContent: justifyContent || '',
      },
    }
  }

  @Watch('controller.courierShipmentCancelListData')
  onSetShipmentCancelData(data: CourierShipmentCancels): void {
    this.tableData = <ITableCell[][]>data.data?.map((cancel, index) => {
      return [
        this.tableCellMapper(
          index +
            1 +
            this.parameters.perPage * (this.parameters.page - 1) +
            '.',
          '60px',
          'center'
        ),
        this.tableCellMapper(
          {
            shipmentId: <string>cancel?.shipmentId,
            bookingId: <string>cancel?.bookingId,
          },
          '160px'
        ),
        this.tableCellMapper(<string>cancel.historyId, '140px'),
        this.tableCellMapper(
          Utils.formatTimeZone(
            Utils.formatDateWithIDLocale(
              <string>cancel.createdAt,
              'DD MMM YYYY HH:mm Z'
            )
          ),
          '188px'
        ),
        this.tableCellMapper(<string>cancel.cancelReason, '188px'),
        this.tableCellMapper(<string>cancel.cancelStatus, '130px', 'center'),
        this.tableCellMapper(<string>cancel.historyId, '196px', 'center'),
      ]
    })
    this.courierData = {
      courierName: <string>data.courierName,
      courierId: <string>data.courierId,
      courierPhoneNumber: <string>data.phoneNumber,
      courierType: <string>data.courierTypeValid,
      courier3LC: <string>data.origin,
    }
    this.paginationData = <Pagination>data.pagination
  }
}
